import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { lastValueFrom, Subject } from 'rxjs';

import { EmployeeProject } from 'src/app/shared/models';
import { AlertService, AlertType, ReportService, RequestService } from 'src/app/shared/services';

@Component({
  selector: 'app-client-hours',
  templateUrl: './client-hours.component.html',
  styleUrls: ['./client-hours.component.scss'],
})
export class ClientHoursComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  isLoading = false;

  constructor(
    private alertService: AlertService,
    private requestService: RequestService,
    private reportService: ReportService,
    private translate: TranslateService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onSubmit({
    fromDate,
    project,
    toDate,
  }: {
    fromDate: string;
    project?: EmployeeProject;
    toDate: string;
  }) {
    this.isLoading = true;

    try {
      const buffer = await lastValueFrom(
        this.requestService.downloadLoggedHours(fromDate, toDate, project.id),
      );

      this.reportService.saveAsExcelFile({
        buffer,
        fileName: `Workreport_${project.name}_${moment(fromDate).format('MM/YYYY')}.xlsx`,
      });
    } catch (error) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.CLIENT_HOURS.EXPORT_ERROR').toString(),
        AlertType.error,
      );
    } finally {
      this.isLoading = false;
    }
  }

  async onSubmitSynechron({
                   fromDate,
                   project,
                   toDate,
                 }: {
    fromDate: string;
    project?: EmployeeProject;
    toDate: string;
  }) {
    this.isLoading = true;

    try {
      const buffer = await lastValueFrom(
        this.requestService.downloadLoggedHoursSynechron(fromDate, toDate, project.id),
      );

      this.reportService.saveAsExcelFile({
        buffer,
        fileName: `Workreport_${project.name}_${moment(fromDate).format('MM/YYYY')}.xlsx`,
      });
    } catch (error) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.CLIENT_HOURS.EXPORT_ERROR').toString(),
        AlertType.error,
      );
    } finally {
      this.isLoading = false;
    }
  }
}
