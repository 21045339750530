<div class="page-loader" *ngIf="loading">
  <mat-spinner class="spinner-reset"></mat-spinner>
</div>

<div *ngIf="!loading">
  <h2 class="section-title">
    {{ 'ADMIN.PROJECTS.EDIT_PROJECT.EDIT' | translate }}
    {{ project.name }}
  </h2>
  <div class="back-link">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <a [routerLink]="['../../']">{{
      'ADMIN.PROJECTS.EDIT_PROJECT.BACK_TO_PROJECTS' | translate
    }}</a>
  </div>

  <form (ngSubmit)="handleSubmit()">
    <mat-form-field>
      <mat-label>{{ 'ADMIN.PROJECTS.EDIT_PROJECT.PROJECT_NAME' | translate }}</mat-label>
      <input
        matInput
        type="text"
        placeholder="Ex.: VistaJet"
        [formControl]="form.controls.name"
        name="projectName"
      />
      <mat-error
        *ngIf="
          form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)
        "
      >
        <ng-container *ngIf="form.controls.name.errors?.required">
          {{ 'SHARED.VALIDATION.REQUIRED' | translate }}.
        </ng-container>
      </mat-error>
    </mat-form-field>

    <div>
      <mat-label class="label">{{
        'ADMIN.PROJECTS.EDIT_PROJECT.PERSONS_OF_INTEREST' | translate
      }}</mat-label>
      <div class="columns">
        <app-autocomplete-person
          required
          [label]="'ADMIN.PROJECTS.EDIT_PROJECT.PARTNERSHIP_MANAGER' | translate"
          [options]="employees"
          [controller]="form.controls.manager"
        ></app-autocomplete-person>
        <app-autocomplete-person
          required
          [label]="'ADMIN.PROJECTS.EDIT_PROJECT.PROJECT_EM' | translate"
          [options]="employees"
          [controller]="form.controls.engineeringManager"
        ></app-autocomplete-person>
        <app-autocomplete-person
          [label]="'ADMIN.PROJECTS.EDIT_PROJECT.PROJECT_LEAD' | translate"
          [options]="employees"
          [controller]="form.controls.lead"
        ></app-autocomplete-person>
      </div>
    </div>

    <mat-form-field>
      <mat-label>{{ 'ADMIN.PROJECTS.EDIT_PROJECT.ORACLE_EXTERNAL_ID' | translate }}</mat-label>
      <input
        matInput
        type="text"
        [formControl]="form.controls.oracleExternalId"
        name="oracleExternalId"
      />
    </mat-form-field>


    <div *ngIf="project.employees.length">
      <mat-label class="label">{{ 'ADMIN.PROJECTS.EDIT_PROJECT.EMPLOYEES' | translate }}</mat-label>
      <mat-chip-listbox aria-label="Fish selection">
        <a
          mat-chip
          class="clickable"
          [routerLink]="['/admin/employees', employee.id]"
          *ngFor="let employee of project.employees"
        >
          {{ employee.fullName }}
        </a>
      </mat-chip-listbox>
    </div>

    <div>
      <mat-label class="block">{{
        'ADMIN.PROJECTS.EDIT_PROJECT.DESCRIPTION' | translate
      }}</mat-label>
      <app-editor [formControl]="form.controls.description" name="projectDescription"></app-editor>
    </div>

    <button type="submit" mat-raised-button color="primary">
      {{ 'SHARED.SUBMIT' | translate }}
    </button>
  </form>
</div>
